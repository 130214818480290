import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import {
  getProcedure,
  removeProcedure,
  reorderProcedureStage,
  updateProcedure,
  updateProcedureStage,
  updateStageTask,
} from "~/services/apis"
import { useProcedure, useProcedureStage, useStageTask } from "~/hooks"
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Drawer,
  message,
  Modal,
  Popconfirm,
  Progress,
  Tag,
} from "antd"
import usePageConfigContext from "~/hooks/usePageConfigContext"
import {
  HolderOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  StopOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
  ArrowRightOutlined,
  CommentOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import AddStageModal from "./AddStageModal"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import AddTaskModal from "./AddTaskModal"
import { UserTag } from "~/components"
import { debounce } from "lodash"
import AssignToModal from "./AssignToModal"
import { getAvatarBackgroundColor } from "~/utils"
import { TASK_STATUS_COLOR } from "~/constants"
import CommentList from "./CommentList"
import useDiary from "~/hooks/useDiary"

function ProcedurePage() {
  const { id } = useParams()
  const history = useHistory()
  const [procedure, setProcedure] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [updatingProcedureStage, setUpdatingProcedureStage] = useState(false)
  const [addStageModalOpen, setAddStageModalOpen] = useState(false)
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false)
  const [assignedToModalOpen, setAssignedToModalOpen] = useState(false)
  const [commentListOpen, setCommentListOpen] = useState(false)
  const [commentModalData, setCommentModalData] = useState(null)
  const [selectedStageTasks, setSelectedStageTasks] = useState([])
  const { setPageTitle } = usePageConfigContext()
  const viewStageTaskRef = useRef(null)
  const viewProcedureStageRef = useRef(null)

  const {
    show: showDiaryDrawer,
    hide: hideDiaryDrawer,
    DiaryDrawer,
  } = useDiary({
    procedureId: id,
    loading,
  })

  const handleOnDragEnd = (result) => {
    if (!result.destination) return

    const newItems = Array.from(procedure?.procedureStages)
    const [reorderedItem] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, reorderedItem)

    setProcedure({
      ...procedure,
      procedureStages: newItems,
    })

    return debounceUpdateProcedure({
      ...procedure,
      procedureStages: newItems,
    })
  }

  const debounceUpdateProcedure = debounce(
    useCallback(async ({ id, procedureStages }) => {
      await reorderProcedureStage(id, {
        procedureStageIds: procedureStages.map(({ id }) => id),
      })

      return onRefresh()
    }, []),
    300,
  )

  //fetch procedure
  const fetchProcedure = async () => {
    try {
      setFetching(true)
      const result = await getProcedure(id)

      if (result?.procedure) {
        setProcedure(result.procedure)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    fetchProcedure()
  }, [id])

  useEffect(() => {
    setPageTitle(
      procedure?.name || "Lorem Ipsum has been the industry's standard dummy text ever since",
    )
  }, [procedure])

  const onProcedureSubmit = async (values) => {
    try {
      setLoading(true)
      const result = await updateProcedure(id, values)

      if (result?.updateProcedure) {
        message.success("Cập nhật quy trình thành công !")
        hideProcedureDrawer()
        fetchProcedure()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  const onProcedureRemove = async () => {
    try {
      setLoading(true)
      const result = await removeProcedure(id)

      if (result?.removeProcedure) {
        message.success("Xóa quy trình thành công !")
        history.push("/diaries/procedures")
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const {
    show: showProcedureDrawer,
    hide: hideProcedureDrawer,
    ProcedureDrawer,
  } = useProcedure({
    procedure: {
      ...procedure,
      startDate: procedure?.startDate ? dayjs(procedure?.startDate) : null,
      completedAt: procedure?.completedAt ? dayjs(procedure?.completedAt) : null,
      project: {
        label: procedure?.project?.name,
        value: procedure?.project?.id,
      },
      stages: procedure?.procedureStages?.map(({ stage }) => ({
        label: stage?.name,
        value: stage?.id,
      })),
    },
    loading,
    onSubmit: onProcedureSubmit,
    onRemove: onProcedureRemove,
  })

  const onStageTaskSubmit = async (values) => {
    try {
      const result = await updateStageTask(viewStageTaskRef.current?.id, values)

      if (result.updateStageTask) {
        hideStageTaskDrawer()
        viewStageTaskRef.current = null
        message.success("Cập nhật công việc thành công !")

        onRefresh()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onProcedureStageSubmit = async (values) => {
    try {
      setUpdatingProcedureStage(true)
      const result = await updateProcedureStage(viewProcedureStageRef.current?.id, values)

      if (result.updateProcedureStage) {
        hideProcedureStageDrawer()
        viewProcedureStageRef.current = null
        message.success("Cập nhật giai đoạn thành công !")

        onRefresh()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setUpdatingProcedureStage(false)
    }
  }

  const {
    show: showProcedureStageDrawer,
    hide: hideProcedureStageDrawer,
    ProcedureStageDrawer,
  } = useProcedureStage({
    width: "800",
    loading: updatingProcedureStage,
    procedureStage: {
      ...viewProcedureStageRef.current,
    },
    onSubmit: onProcedureStageSubmit,
    onClose: () => {
      viewProcedureStageRef.current = null
      hideProcedureStageDrawer()
    },
  })

  const {
    show: showStageTaskDrawer,
    hide: hideStageTaskDrawer,
    StageTaskDrawer,
  } = useStageTask({
    width: "800",
    stageTask: {
      ...viewStageTaskRef.current,
      startedAt: viewStageTaskRef.current?.startedAt
        ? dayjs(viewStageTaskRef.current.startedAt)
        : undefined,
      completedAt: viewStageTaskRef.current?.completedAt
        ? dayjs(viewStageTaskRef.current.completedAt)
        : undefined,
      assignedTo: viewStageTaskRef.current?.assignedTo
        ? {
            label: (
              <span>
                {viewStageTaskRef.current?.assignedTo?.firstName}
                &nbsp;{viewStageTaskRef.current?.assignedTo?.lastName} /&nbsp;
                <UserTag value={viewStageTaskRef.current?.assignedTo?.role} />
              </span>
            ),
            value: viewStageTaskRef.current?.assignedTo?.id,
          }
        : undefined,
      stageTaskSupplies: viewStageTaskRef.current?.stageTaskSupplies?.map((stageTaskSupply) => ({
        ...stageTaskSupply,
        supply: {
          label: stageTaskSupply?.supply?.name,
          value: stageTaskSupply?.supply?.id,
        },
      })),
    },
    onSubmit: onStageTaskSubmit,
    onClose: () => {
      viewStageTaskRef.current = null
      hideStageTaskDrawer()
    },
  })

  if (fetching) {
    return (
      <div className="relative grid h-screen w-full place-items-center">
        <LoadingOutlined className="text-[32px]" />
      </div>
    )
  }

  const onRefresh = () => {
    fetchProcedure()
    setAddStageModalOpen(false)
    setAddTaskModalOpen(false)
    setAssignedToModalOpen(false)
  }

  const onBulkComplete = async () => {
    const promises = selectedStageTasks.map((id) => {
      return updateStageTask(id, { completedAt: dayjs().toISOString() })
    })

    await Promise.all(promises)

    onRefresh()
    setSelectedStageTasks([])
  }

  const diffDays = dayjs().diff(dayjs(procedure?.startDate), "day") + 1

  const groupTasksByDate = (stageTasks) => {
    const grouped = stageTasks.reduce((acc, task) => {
      const date = dayjs(task.startedAt).format("YYYY-MM-DD")
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(task)
      return acc
    }, {})

    return Object.entries(grouped).sort(([a], [b]) => dayjs(a).diff(dayjs(b)))
  }

  return (
    <div className="max-lg:p-[16px]">
      <div className="flex w-full flex-col items-center justify-between gap-[10px] lg:flex-row">
        <div>
          {procedure?.startDate && (
            <div className="text-[14px] font-medium text-gray-500">
              Ngày bắt đầu: <b>{dayjs(procedure.startDate).format("DD/MM/YYYY")}</b>
            </div>
          )}
        </div>
        <div className="text-end text-[18px] font-medium text-black">
          <Button size="small" className="mr-[6px]" onClick={showDiaryDrawer}>
            <EditOutlined /> Kiểm tra tình trạng
          </Button>
          <Button size="small" className="" onClick={showProcedureDrawer}>
            <EditOutlined /> Cập nhật thông tin
          </Button>
        </div>
      </div>
      {/* {diffDays > 1 && procedure?.executionDays > 0 && (
        <div className="mt-[10px]">
          <Progress
            percent={
              diffDays >= procedure?.executionDays
                ? 99.9999
                : (diffDays * 100) / procedure?.executionDays
            }
            format={() =>
              diffDays > procedure?.executionDays ? (
                <>Đã hoàn thành</>
              ) : (
                `${diffDays} / ${procedure?.executionDays} ngày`
              )
            }
          />
        </div>
      )} */}
      {/* Add Task or Stage */}
      <div>
        <div className="flex flex-row items-center gap-[16px]">
          <h1 className="text-[24px] font-medium max-lg:my-3">Giai đoạn</h1>
        </div>
        <div>
          <div className="flex w-full flex-col justify-between lg:flex-row">
            <div className="my-[10px] flex flex-row items-center">
              <PlusOutlined className="pr-[6px]" />
              <Button onClick={() => setAddTaskModalOpen(true)} type="link" className="px-2">
                Tạo công việc
              </Button>
              <div className="text-gray-500">hoặc</div>
              <Button onClick={() => setAddStageModalOpen(true)} type="link" className="px-2">
                Tạo giai đoạn mới
              </Button>
            </div>
            {selectedStageTasks.length > 0 && (
              <Popconfirm
                title="Bạn có chắc chắn muốn xác nhận hoàn thành tất cả công việc được chọn không ?"
                onConfirm={onBulkComplete}
              >
                <Button size="sm" type="primary" className="max-lg:mb-[16px] lg:mr-[10px]">
                  Xác nhận hoàn thành
                </Button>
              </Popconfirm>
            )}
          </div>
          <Modal
            title={"Thêm giai đoạn"}
            open={addStageModalOpen}
            onCancel={() => setAddStageModalOpen(false)}
            footer={null}
            width={800}
          >
            <AddStageModal procedure={procedure} onFinish={onRefresh} />
          </Modal>

          <Modal
            centered
            title={"Thêm công việc"}
            open={addTaskModalOpen}
            onCancel={() => setAddTaskModalOpen(false)}
            footer={null}
            width={800}
          >
            <AddTaskModal procedure={procedure} onFinish={onRefresh} />
          </Modal>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex flex-col gap-[10px]"
            >
              {procedure?.procedureStages?.map((ps, index) => {
                const groupedTasks = groupTasksByDate(ps.stageTasks)

                return (
                  <Draggable key={ps?.id} draggableId={ps?.id + ""} index={index}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        key={ps?.id}
                        className="group/item flex flex-row gap-[10px] rounded-lg border border-solid border-gray-200 px-2 py-3 shadow lg:px-5"
                        style={{
                          ...provided.draggableProps.style,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <div
                          className="hidden"
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <HolderOutlined className="invisible mt-[4px] text-[16px] text-gray-500 group-hover/item:visible" />
                        </div>
                        <div className="w-full">
                          <div className="flex justify-between">
                            <div className="flex items-center gap-[10px] text-[20px] font-medium">
                              <Checkbox
                                onChange={(e) => {
                                  setSelectedStageTasks(
                                    e.target.checked
                                      ? [
                                          ...selectedStageTasks,
                                          ...ps.stageTasks.map(({ id }) => id),
                                        ]
                                      : selectedStageTasks.filter(
                                          (id) => !ps.stageTasks.find((task) => task.id === id),
                                        ),
                                  )
                                }}
                              />
                              {ps?.stage?.name}{" "}
                            </div>
                            <div>
                              <Button
                                onClick={() => {
                                  viewProcedureStageRef.current = {
                                    ...ps,
                                    startDate: ps?.startDate ? dayjs(ps?.startDate) : null,
                                    completedAt: ps?.completedAt && dayjs(ps?.completedAt),
                                  }

                                  showProcedureStageDrawer()
                                }}
                                type="primary"
                              >
                                <EditOutlined />
                              </Button>
                            </div>
                          </div>
                          <Tag className="my-[12px] rounded-xl text-[12px]">
                            <span className="text-[12px] text-gray-500">
                              <span className="max-lg:hidden">Bắt đầu từ:</span>{" "}
                              <b>
                                {ps?.startDate ? dayjs(ps.startDate).format("DD-MM-YYYY") : "-"}
                              </b>
                              {ps?.completedAt && (
                                <span className="ml-[4px]">
                                  <ArrowRightOutlined />{" "}
                                  <span className="max-lg:hidden">Hoàn thành vào:</span>{" "}
                                  <b>{dayjs(ps.completedAt).format("DD-MM-YYYY")}</b>
                                </span>
                              )}
                            </span>
                          </Tag>
                          {/* <Tag className="my-[12px] rounded-xl text-[12px]">
                            {isCompleted
                              ? "Đã hoàn thành"
                              : isProcessing
                                ? "Đang thực hiện"
                                : "Chưa bắt đầu"}
                          </Tag> */}
                          <div className="ml-[20px] flex flex-col gap-[20px]">
                            {groupedTasks.map(([date, tasks]) => (
                              <div key={date}>
                                <h3
                                  className={`mb-2 text-xl font-medium text-gray-700 ${dayjs().isSame(date, "day") ? "font-bold" : ""}`}
                                >
                                  <CalendarOutlined className="mr-[6px]" />
                                  {dayjs(date).format("DD MMMM YYYY")}{" "}
                                  {dayjs().isSame(date, "day") && " (Hôm nay)"}
                                </h3>
                                {tasks.map((stageTask) => {
                                  const { completedAt, note, task, assignedTo } = stageTask || {}
                                  const isCompleted = !!completedAt
                                  const completedLate =
                                    completedAt && dayjs().isAfter(completedAt, "day")
                                  const isProcessing =
                                    !completedAt && dayjs().isAfter(stageTask?.startedAt)
                                  const isNotStarted =
                                    stageTask?.startedAt && dayjs().isBefore(stageTask?.startedAt)

                                  const isLate =
                                    isProcessing &&
                                    dayjs().isAfter(dayjs(stageTask?.startedAt), "day")

                                  const status = {
                                    [isCompleted]: TASK_STATUS_COLOR.completed,
                                    [completedLate]: TASK_STATUS_COLOR.completed,
                                    [isProcessing]: TASK_STATUS_COLOR.processing,
                                    [isNotStarted]: TASK_STATUS_COLOR.notStarted,
                                    [isLate]: TASK_STATUS_COLOR.late,
                                  }

                                  return (
                                    <div
                                      key={task?.id}
                                      className={`cursor-pointer rounded-lg p-3 shadow-lg ${isCompleted ? "bg-primary-color/5 opacity-80" : "bg-orange-50"}`}
                                      onClick={() => {
                                        viewStageTaskRef.current = stageTask
                                        showStageTaskDrawer()
                                      }}
                                    >
                                      <div className="flex items-start gap-[10px]">
                                        <div onClick={(e) => e.stopPropagation()}>
                                          <Checkbox
                                            className="mt-[1px]"
                                            checked={selectedStageTasks.includes(stageTask?.id)}
                                            onChange={(e) => {
                                              setSelectedStageTasks(
                                                e.target.checked
                                                  ? [...selectedStageTasks, stageTask?.id]
                                                  : selectedStageTasks.filter(
                                                      (id) => id !== stageTask?.id,
                                                    ),
                                              )
                                            }}
                                          />
                                        </div>
                                        <div className="translate-y-[3px]">
                                          {completedAt ? (
                                            <CheckCircleOutlined
                                              style={{
                                                color: "var(--primary-color)",
                                                fontSize: "17px",
                                              }}
                                            />
                                          ) : (
                                            <StopOutlined style={{ fontSize: "16px" }} />
                                          )}
                                        </div>
                                        <div className="flex flex-col overflow-hidden">
                                          <div
                                            className={`cursor-pointer text-[16px] hover:font-semibold ${completedAt ? "text-primary-color" : ""}`}
                                            style={{
                                              display: "-webkit-box",
                                              WebkitLineClamp: 2,
                                              WebkitBoxOrient: "vertical",
                                              overflow: "hidden",
                                              wordBreak: "break-word",
                                              whiteSpace: "normal",
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                          >
                                            <span
                                              className={`${completedAt ? "line-through" : ""}`}
                                            >
                                              {task?.name}
                                            </span>
                                          </div>
                                          <div className="mt-[8px] flex flex-row items-baseline overflow-hidden">
                                            <Tag color={status?.["true"]}>
                                              {!isNotStarted
                                                ? isCompleted
                                                  ? "Đã hoàn thành"
                                                  : isLate
                                                    ? "Chưa thực hiện"
                                                    : "Đang thực hiện"
                                                : "Chưa bắt đầu"}
                                            </Tag>
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                setCommentListOpen(true)
                                                setCommentModalData(stageTask)
                                              }}
                                              className="cursor-pointer text-[12px] text-gray-500 hover:underline"
                                            >
                                              <CommentOutlined className="text-[16px] text-black lg:hidden" />
                                              <span className="max-lg:hidden">
                                                <span className="font-semibold">
                                                  ({stageTask?.comments?.length || 0})
                                                </span>
                                                Thảo luận
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="grow" />
                                        <div className="lg:w-[120px]">
                                          {assignedTo && (
                                            <div
                                              className="flex cursor-pointer items-center gap-[6px] hover:underline"
                                              onClick={() => {
                                                viewStageTaskRef.current = stageTask
                                                setAssignedToModalOpen(true)
                                              }}
                                            >
                                              <Avatar
                                                style={{
                                                  backgroundColor: getAvatarBackgroundColor(
                                                    assignedTo?.firstName?.[0],
                                                  ),
                                                }}
                                                size={"small"}
                                              >
                                                {assignedTo?.firstName?.[0] || ""}
                                              </Avatar>{" "}
                                              <div className="line-clamp-1">
                                                {assignedTo?.firstName}
                                              </div>
                                            </div>
                                          )}
                                          {!assignedTo && (
                                            <div>
                                              <Button
                                                className="text-[12px]"
                                                type="dashed"
                                                size="small"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  viewStageTaskRef.current = stageTask
                                                  setAssignedToModalOpen(true)
                                                }}
                                              >
                                                <PlusOutlined /> Giao việc
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Modal
        centered
        title={"Giao việc"}
        open={assignedToModalOpen}
        onCancel={() => setAssignedToModalOpen(false)}
        footer={null}
        width={800}
      >
        <AssignToModal
          stageTaskId={viewStageTaskRef.current?.id}
          onFinish={() => {
            viewStageTaskRef.current = null
            onRefresh()
          }}
        />
      </Modal>
      <Drawer
        placement="right"
        width={"800"}
        title={"Thảo luận"}
        open={commentListOpen}
        onClose={() => setCommentListOpen(false)}
      >
        <CommentList
          stageTaskId={commentModalData?.id}
          comments={commentModalData?.comments}
          onFinish={() => {
            onRefresh?.()
            setCommentListOpen(false)
          }}
        />
      </Drawer>
      <StageTaskDrawer />
      {/* Add Task or Stage */}
      {/* <StageTab
        procedureStages={procedure?.procedureStages}
        onRequestUpdate={showProcedureDrawer}
        current={currentStage}
        setCurrent={setCurrentStage}
      /> */}
      <ProcedureDrawer />
      <ProcedureStageDrawer />
      <DiaryDrawer />
    </div>
  )
}

export default ProcedurePage

import { EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Col, Divider, Form, Image, List, Modal, Row, Spin } from "antd"
import React, { useState, useEffect } from "react"
import { useSetState } from "react-use"
import { createDiary, getDiaries, updateDiary } from "~/services/apis"
import { DatePicker } from "../DatePicker"
import CustomEditor from "../Editor"
import CustomUpload from "../Upload"
import dayjs from "dayjs"

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
}

const initialValues = {
  date: dayjs(),
  description: "",
  images: [],
}

function DiaryContainer({ procedureId, width = "80%", onClose }) {
  const [params, setParams] = useSetState(DEFAULT_PARAMS)
  const [diaries, setDiaries] = useState([])
  const [showDiaryForm, setShowDiaryForm] = useState(false)
  const [editingDiary, setEditingDiary] = useState(null)
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState(null)

  const [form] = Form.useForm()

  const fetchDiaries = async () => {
    try {
      const { data, headers } = await getDiaries(params, { procedureId })
      if (data?.diaries?.length > 0) {
        setDiaries((prev) => [...prev, ...data.diaries])
        setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchMoreDiaries = async () => {
    if (loading) {
      return
    }

    setParams({ ...params, page: params.page + 1 })
  }

  useEffect(() => {
    fetchDiaries(params)
  }, [params])

  const handleSubmit = async (values) => {
    const submitValues = {
      ...values,
      procedureId,
      date: dayjs(values.date).format("YYYY-MM-DD"),
    }

    setLoading(true)
    try {
      let result
      if (editingDiary?.id) {
        result = await updateDiary(editingDiary?.id, submitValues)
      } else {
        result = await createDiary(submitValues)
      }

      if (result?.createDiary || result?.updateDiary) {
        setShowDiaryForm(false)
        setEditingDiary(null)
        form.resetFields()

        if (result?.createDiary) {
          setDiaries((prev) =>
            [result.createDiary, ...prev].sort(
              (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
            ),
          )
        } else {
          setDiaries((prev) =>
            prev.map((diary) => (diary.id === editingDiary?.id ? result.updateDiary : diary)),
          )
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {diaries?.length === 0 && (
        <div className="grid h-[300px] place-items-center text-center text-[18px] font-medium text-gray-500">
          <div className="flex flex-col gap-3">
            Chưa có dữ liệu
            <Button type="primary" onClick={() => setShowDiaryForm(true)}>
              <PlusOutlined /> Thêm bản ghi
            </Button>
          </div>
        </div>
      )}
      {diaries?.length > 0 && (
        <div>
          <div className="flex justify-end">
            <Button type="primary" onClick={() => setShowDiaryForm(true)}>
              <PlusOutlined /> Thêm bản ghi
            </Button>
          </div>

          <Divider />

          <List
            dataSource={diaries}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <div className="w-full">
                  <div className="flex items-baseline justify-between">
                    <div className="text-[20px] font-medium">
                      Ngày {dayjs(item.date).format("DD/MM/YYYY")}
                    </div>

                    <div>
                      <Button
                        size="small"
                        type="text"
                        className="text-primary-color"
                        onClick={() => {
                          setEditingDiary(item)
                          setShowDiaryForm(true)
                          form.setFieldsValue({
                            ...item,
                            date: dayjs(item.date),
                          })
                        }}
                      >
                        <EditOutlined /> Cập nhật
                      </Button>
                    </div>
                  </div>

                  <div className="mt-5">
                    <span className="text-[16px]">Hình ảnh</span>
                    <div className="flex flex-wrap gap-3 [&_*]:rounded-lg [&_*]:shadow-lg">
                      <Image.PreviewGroup>
                        {item.imageUrls?.map((image) => (
                          <Image key={image} src={image} alt="diary" width={125} height={125} />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  </div>

                  <div className="my-5 [&_.ck-content]:!rounded-xl [&_.ck-content]:!border-black [&_.ck-editor]:!w-full [&_.ck-sticky-panel]:!hidden [&_.ck-toolbar]:!hidden">
                    <div className="mb-[4px] text-[16px]">Nội dung</div>
                    <CustomEditor value={item.description} disabled />
                  </div>
                </div>
              </List.Item>
            )}
          />

          <div className="flex justify-center">
            {meta?.totalPages > params.page && (
              <Button onClick={fetchMoreDiaries}> Tải thêm</Button>
            )}
          </div>
        </div>
      )}
      <Modal
        open={showDiaryForm}
        onOk={() => form.submit()}
        onCancel={() => setShowDiaryForm(false)}
        confirmLoading={loading}
        width={800}
      >
        <Form form={form} onFinish={handleSubmit} initialValues={initialValues} layout="vertical">
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item label="Thời gian" name="date" rules={[{ required: true }]}>
                <DatePicker inputReadonly className="w-full" format={"HH:mm DD/MM/YYYY"} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item label="Hình ảnh" name="imageUrls" rules={[{ required: true }]}>
                <CustomUpload multiple />
              </Form.Item>
            </Col>

            <Col xs={24} lg={24}>
              <Form.Item label="Nội dung" name="description" rules={[{ required: true }]}>
                <CustomEditor />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default DiaryContainer

import { CheckOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { Calendar } from "~/components"
import CustomEditor from "~/components/Editor"
import CustomUpload from "~/components/Upload"
import {
  createFarmDiary,
  getAllFarmDiaries,
  removeFarmDiary,
  updateFarmDiary,
} from "~/services/apis"

const CellRender = ({ value, isSelected, diaries }) => {
  return (
    <div className="p-1">
      <div
        className={`flex h-full w-full items-center justify-center rounded-lg p-1 ${diaries?.length ? "bg-green-700 text-white" : ""} ${isSelected ? "border border-solid !bg-white !text-primary-color" : ""} `}
      >
        {value.format("DD")}
      </div>
    </div>
  )
}

const FarmDiariesPage = () => {
  const [farmDiaries, setFarmDiaries] = useState({})
  const [current, setCurrent] = useState(dayjs())
  const [form] = Form.useForm()
  const [updatingForm, setUpdatingForm] = useState(false)
  const [editingDiaryId, setEditingDiaryId] = useState(null)

  useEffect(() => {
    getAllFarmDiaries().then((res) => {
      setFarmDiaries(
        res.data.farmDiaries.reduce((acc, curr) => {
          const dateKey = dayjs(curr.date).format("YYYY-MM-DD")
          acc[dateKey] = acc[dateKey] || []
          acc[dateKey].push(curr)
          return acc
        }, {}),
      )
    })
  }, [])

  const cellRender = (value, info) => {
    if (info.type === "date")
      return (
        <CellRender
          value={value}
          isSelected={dayjs(current).isSame(value, "day")}
          diaries={farmDiaries?.[dayjs(value).format("YYYY-MM-DD")]}
        />
      )
    return info.originNode
  }

  const handleSubmit = async (values) => {
    try {
      const dateKey = dayjs(current).format("YYYY-MM-DD")
      let result

      if (editingDiaryId) {
        result = await updateFarmDiary(editingDiaryId, values)
        const updatedDiaries = farmDiaries[dateKey].map((diary) =>
          diary.id === editingDiaryId ? result.updateFarmDiary : diary,
        )
        setFarmDiaries({
          ...farmDiaries,
          [dateKey]: updatedDiaries,
        })
      } else {
        result = await createFarmDiary({
          ...values,
          date: dateKey,
        })
        // Add new diary to the beginning of the array
        setFarmDiaries({
          ...farmDiaries,
          [dateKey]: [result.createFarmDiary, ...(farmDiaries[dateKey] || [])],
        })
      }

      setUpdatingForm(false)
      setEditingDiaryId(null)
      form.resetFields()

      message.success(editingDiaryId ? "Cập nhật nhật ký thành công!" : "Tạo nhật ký thành công!")
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = (diary) => {
    setEditingDiaryId(diary.id)
    setUpdatingForm(true)
    form.setFieldsValue({
      title: diary.title,
      description: diary.description,
      imageUrls: diary.imageUrls,
    })
  }

  const handleDelete = (diary) => {
    removeFarmDiary(diary.id).then(() => {
      setFarmDiaries({
        ...farmDiaries,
        [dayjs(current).format("YYYY-MM-DD")]: farmDiaries[
          dayjs(current).format("YYYY-MM-DD")
        ].filter((d) => d.id !== diary.id),
      })
    })
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={8}>
        <div>
          <Calendar
            value={current}
            onChange={(value) => {
              setCurrent(value)
              setUpdatingForm(false)
              form.setFieldsValue({
                id: farmDiaries?.[dayjs(value).format("YYYY-MM-DD")]?.id,
                description: farmDiaries?.[dayjs(value).format("YYYY-MM-DD")]?.description,
                imageUrls: farmDiaries?.[dayjs(value).format("YYYY-MM-DD")]?.imageUrls,
              })
            }}
            fullscreen={false}
            fullCellRender={cellRender}
          />
        </div>
      </Col>
      <Col xs={24} lg={16}>
        <h2 className="text-center text-2xl font-normal">
          Nhật ký Farm ngày <b>{current.format("DD/MM/YYYY")}</b>
        </h2>

        {!updatingForm && (
          <div className="mt-4 flex justify-center">
            <Button
              onClick={() => {
                setUpdatingForm(true)
                setEditingDiaryId(null)
                form.resetFields()
              }}
              type="link"
              icon={<PlusOutlined />}
            >
              Thêm nhật ký
            </Button>
          </div>
        )}

        {updatingForm && !editingDiaryId && (
          <div className="mt-4 rounded-lg border p-4 shadow-lg">
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Row>
                <Col xs={24} lg={24}>
                  <Form.Item label="Hình ảnh thêm" name="imageUrls">
                    <CustomUpload multiple readOnly={!updatingForm} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={18}>
                  <Form.Item label="Tiêu đề" name="title" rules={[{ required: true }]}>
                    <Input
                      readOnly={!updatingForm}
                      placeholder="Nhập tiêu đề (Tên công việc, tình trạng cây,...)"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item label="Nội dung" name="description" rules={[{ required: true }]}>
                    <CustomEditor disabled={!updatingForm} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-end gap-2">
                <Button
                  onClick={() => {
                    setUpdatingForm(false)
                    setEditingDiaryId(null)
                    form.resetFields()
                  }}
                >
                  Hủy
                </Button>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </div>
            </Form>
          </div>
        )}

        {farmDiaries[dayjs(current).format("YYYY-MM-DD")]?.map((diary, index) => (
          <div
            key={diary.id}
            className={`relative mt-4 rounded-lg border p-4 ${editingDiaryId === diary.id ? "shadow-lg" : ""}`}
          >
            {editingDiaryId === diary.id ? (
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row>
                  <Col xs={24} lg={24}>
                    <Form.Item label="Hình ảnh thêm" name="imageUrls">
                      <CustomUpload multiple readOnly={!updatingForm} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={18}>
                    <Form.Item label="Tiêu đề" name="title" rules={[{ required: true }]}>
                      <Input readOnly={!updatingForm} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={24}>
                    <Form.Item label="Nội dung" name="description" rules={[{ required: true }]}>
                      <CustomEditor disabled={!updatingForm} />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="flex justify-end gap-2">
                  <Button
                    onClick={() => {
                      setUpdatingForm(false)
                      setEditingDiaryId(null)
                      form.resetFields()
                    }}
                  >
                    Hủy
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Lưu
                  </Button>
                </div>
              </Form>
            ) : (
              <>
                <div className="flex justify-end gap-1">
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => handleEdit(diary)}
                    disabled={updatingForm}
                  />

                  <Popconfirm
                    title="Bạn có chắc chắn muốn xóa nhật ký này?"
                    onConfirm={() => handleDelete(diary)}
                  >
                    <Button
                      type="link"
                      icon={<DeleteOutlined className="text-red-500" />}
                      disabled={updatingForm}
                    />
                  </Popconfirm>
                </div>
                <CustomUpload value={diary.imageUrls} readOnly multiple />
                <span className="text-lg font-semibold">{diary.title}</span>
                <div className="mt-4 [&_.ck-content]:!rounded-xl [&_.ck-content]:!border-black [&_.ck-editor]:!w-full [&_.ck-sticky-panel]:!hidden [&_.ck-toolbar]:!hidden">
                  <CustomEditor value={diary.description} disabled />
                </div>
                {index !== farmDiaries[dayjs(current).format("YYYY-MM-DD")].length - 1 && (
                  <Divider />
                )}
              </>
            )}
          </div>
        ))}
      </Col>
    </Row>
  )
}

export default FarmDiariesPage
